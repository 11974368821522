<template>
  <moe-page title="退货地址管理">
    <moe-table ref="areasTableRef" url="/shop/returnAreas/list" :params="returnAreasParams" :numberShow="false">
      <el-button slot="tool" type="primary" icon="el-icon-circle-plus-outline" @click="handleAdd()">新增退货地址</el-button>

      <el-table-column label="编号" prop="id" width="100" />
      <el-table-column label="联系人" prop="contacts" :show-overflow-tooltip="true" />
      <el-table-column label="联系号码" prop="contactPhone" :show-overflow-tooltip="true" />
      <el-table-column label="省" prop="province" :show-overflow-tooltip="true" />
      <el-table-column label="市" prop="city" :show-overflow-tooltip="true" />
      <el-table-column label="区" prop="county" :show-overflow-tooltip="true" />
      <el-table-column label="详细地址" prop="address" :show-overflow-tooltip="true" />
      <el-table-column prop="createTime" label="创建时间" min-width="150">
        <template slot-scope="{ row }">{{ $moe_time.getTimeStamp(row.createTime) }}</template>
      </el-table-column>
      <el-table-column label="备注" prop="remark" :show-overflow-tooltip="true" />
      <el-table-column label="状态" min-width="150">
        <template slot-scope="{ row }">
          <moe-switch @change="(e) => req_updateReturnAreasStatus(row, e)" :activeValue="1" :inactiveValue="2" v-model="row.status" activeText="启用" inactiveText="禁用"></moe-switch>
        </template>
      </el-table-column>

      <el-table-column label="操作" width="200" fixed="right">
        <template slot-scope="{ row, $index }">
          <el-button type="primary" size="mini" icon="el-icon-edit" @click="handleEdit(row)">编辑</el-button>
          <el-button icon="el-icon-delete" size="small" type="danger" @click="req_delReturnAreas(row, $index)">删除</el-button>
        </template>
      </el-table-column>
    </moe-table>

    <moe-dialog :show="showDialog" title="新增退货地址" width="70%" :autoHeight="true" @close="handleDialogClose()">
      <moe-form
        ref="areasForm"
        :showBack="false"
        :model="areasParams"
        :rules="rules">

        <el-form-item label="选择地址" prop="provinceId">
          <el-cascader ref="cascaderRef" class="w-400" :props="props" v-model="cascaderValue" placeholder="请选择地址" @change="changeCascader" clearable></el-cascader>
        </el-form-item>

        <el-form-item label="详细地址" prop="address">
          <el-input type="textarea" :rows="5" placeholder="请输入详细地址" maxlength="100" clearable v-model="areasParams.address"></el-input>
        </el-form-item>

        <el-form-item label="联系人" prop="contacts">
          <el-input v-model.trim="areasParams.contacts" placeholder="请输入联系人" clearable maxlength="30"></el-input>
        </el-form-item>

        <el-form-item label="手机" prop="contactPhone">
          <el-input placeholder="请输入手机" clearable maxlength="30" :value="areasParams.contactPhone" @input="(value) => areasParams.contactPhone = $moe_formatter.formatterNoChinese(value)"></el-input>
        </el-form-item>

        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" :rows="5" placeholder="输入备注" maxlength="100" clearable v-model="areasParams.remark"></el-input>
        </el-form-item>

      </moe-form>
      <template slot="footer">
        <el-button @click="handleDialogClose()">取消</el-button>
        <el-button type="primary" @click="handleConfirm()">确定</el-button>
      </template>
    </moe-dialog>
  </moe-page>
</template>

<script>
import SYSTEM_API from '@/api/modules/system';
export default {
  name: '',
  data() {
    const verify = this.$moe_verify.verifyForm
    const rules = {
      name: verify.isExtent(['请输入活动名称', '长度为 2 ~ 16位'], '2~16'),
      provinceId: verify.isEmpty('请选择地址'),
      address: verify.isEmpty('请输入详细地址'),
      contacts: verify.isEmpty('请输入联系人'),
      contactPhone: verify.isEmpty('请输入手机'),
    };
    return {
      returnAreasParams: {
        pageNum: 1,
        pageSize: 10,
      },
      showDialog: false,
      areasParams: {
        id: '',
        province: '',
        provinceId: '',
        city: '',
        cityId: '',
        county: '',
        countyId: '',
        address: '',
        contacts: '',
        contactPhone: '',
        remark: '',
        status: 2,
      },
      rules,
      cascaderValue: [],
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          const { level, data } = node;
          let params = {
            path: level + 1,
          }
          if (level != 0) {
            params.parentId = data.value
          }
          let nodes = [];
          setTimeout(() => {
            SYSTEM_API.getQueryAreas(params).then((data) => {
              if (data.code === 200) {
                nodes = data.result.map(({ name, areaId }) => {
                  return {
                    label: name,
                    value: areaId,
                    leaf: level >= 2
                  }
                })
              }
              resolve(nodes)
            })
          }, 200);
        }
      },
    }
  },
  methods: {
    handleAdd() {
      this.showDialog = true;
    },
    handleEdit({ id }) {
      this.$moe_api.SYSTEM_API.getReturnAreasDetail({ id }).then((data) => {
        if (data.code === 200) {
          let result = data.result;
          for (let key in this.areasParams) {
            this.areasParams[key] = result[key];
          }

          let { provinceId, cityId, countyId } = this.areasParams;
          this.cascaderValue = [provinceId, cityId, countyId];

          this.$nextTick(() => {
            this.showDialog = true;
          })
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    /** dialog关闭 */
    handleDialogClose() {
      this.cascaderValue = [];
      this.$refs['areasForm'].resetFields();
      this.showDialog = false;
    },
    handleConfirm() {
      this.$refs['areasForm'].validate(() => {
        if (this.areasParams.id) {
          this.req_updateReturnAreas();
        } else {
          this.req_addReturnAreas();
        }
      })
    },
    changeCascader(selectList) {
      if (selectList.length) {
        this.areasParams.provinceId = selectList[0]
        this.areasParams.cityId = selectList[1]
        this.areasParams.countyId = selectList[2]
      } else {
        this.areasParams.provinceId = this.areasParams.cityId = this.areasParams.countyId = '';
      }

      let selectTextList = this.$refs['cascaderRef'].getCheckedNodes()[0].pathLabels

      if (selectTextList.length) {
        this.areasParams.province = selectTextList[0]
        this.areasParams.city = selectTextList[1]
        this.areasParams.county = selectTextList[2]
      } else {
        this.areasParams.province = this.areasParams.city = this.areasParams.county = '';
      }
    },
    /** 删除退货地址 */
    req_delReturnAreas({ id, contacts }) {
      this.$moe_layer.confirm(`您确定要删除当前选项"${contacts}"吗？`, () => {
        this.$moe_api.SYSTEM_API.delReturnAreas({ id }).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('删除成功', {
              completionHandler: () => {
                this.$refs['areasTableRef'].searchData();
              }
            });
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      });
    },
    /** 更新退货地址状态 */
    req_updateReturnAreasStatus({ id }, status) {
      this.$moe_api.SYSTEM_API.updateReturnAreasStatus({ id, status }).then((data) => {
          if (data.code === 200) {
            this.$moe_msg.success('编辑成功');
          } else {
            this.$moe_msg.error(data.message);
          }
        });
    },
    /** 创建退货地址 */
    req_addReturnAreas() {
      this.$moe_api.SYSTEM_API.addReturnAreas(this.areasParams).then((data) => {
        if (data.code === 200) {
          this.$moe_msg.success('新增成功', {
            completionHandler: () => {
              this.handleDialogClose();
              this.$refs['areasTableRef'].searchData();
            }
          });
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    /** 修改退货地址 */
    req_updateReturnAreas() {
      this.$moe_api.SYSTEM_API.updateReturnAreas(this.areasParams).then((data) => {
        if (data.code === 200) {
          this.$moe_msg.success('编辑成功', {
            completionHandler: () => {
              this.handleDialogClose();
              this.$refs['areasTableRef'].searchData();
            }
          });
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    }
  }
}
</script>

<style lang="scss">

</style>